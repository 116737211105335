import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="prose mx-auto text-prose-xl">
      <p>{`The inspiration for this large mural came from hand drawn maps. I wanted bold color to describe the incredible local topography of the Cascade Mountains and Columbia River Valley. The foreground emphasize the majesty of the Cascade Mountain Range with some 8000ft peaks. The middle ground takes you down to the fertile region of the Columbia River at around 2600ft. The top portion is pink to reflect the arid Waterville Plateau at around around 2739 ft.`}</p>
      <p><a parentName="p" {...{
          "href": "https://youtu.be/_6c_f0ZjbW0"
        }}>{`https://youtu.be/_6c_f0ZjbW0`}</a></p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      